import { useState, useRef } from "react";
import MaskedInput from "../components/masked-input";
import { cpf } from "cpf-cnpj-validator";
import RegistrationDataDisplay from "../components/registration-data-display";
import validators from "../utils/date-validator";
import { firebaseStorage } from "../base";
import { firestoreDocuments } from "../base";
import { useNavigate } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import { ref, uploadBytesResumable, getDownloadURL } from "@firebase/storage";
import { addDoc, collection } from "@firebase/firestore";
import { getAuth, signInAnonymously } from "firebase/auth";
import UserAlertModal from "../components/modal";
import TipCard from "../components/tip-card";
import * as EmailValidator from "email-validator";
import { v4 as uuidv4 } from 'uuid';
import TagManager from "react-gtm-module";
import { useEffect } from "react";

function RegisterFormComplete() {

  const { getRootProps, getInputProps } = useDropzone({
    accept: ".pdf",
    multiple: true,
    onDrop: (acceptedFiles, fileRejections) => {
      if (fileRejections.length > 0) {
        setFileError(true);
        setDragState(false);
        return;
      }
      setFileError(false);
      setDragState(false);
      uploadToCloud(acceptedFiles[0]);
    },
    onDragEnter: (event) => {
      setDragState(true);
      console.log(event);
    },
    onDragOver: (event) => {
      event.preventDefault();
    },
  });

  const navigate = useNavigate();
  const [formStepState, setformStepState] = useState({
    index: 0,
  });

  useEffect(() => {
    TagManager.dataLayer({
      gtmId: process.env.REACT_APP_GTM_ID,
      dataLayer: {
        event: 'forestSignUp',
        signUpStep: formStepState.index + 1,
      },
    });

    if (formStepState.index !== 0) {
      TagManager.dataLayer({
        dataLayer :{
          appName: 'Moss Forest',
          visitorLoginState: 'OFF',
          pageType: 'registration'
        }
      });
  
      TagManager.dataLayer({
        dataLayer: {
          event: 'virtualPageView',
          pageTitle: document.title,
          pageURL: window.location.href,
        }
      });
    }

  }, [formStepState.index]);

  function advanceForm() {
    setformStepState({
      ...formStepState,
      index: formStepState.index + 1,
    });
  }
  function returnForm() {
    if (formStepState.index > 0) {
      setformStepState({
        ...formStepState,
        index: formStepState.index - 1,
      });
    } else {
      navigate("/how-it-works");
    }
  }
  const [isModalVisible, setModalVisible] = useState(false);
  const [isDragging, setDragState] = useState(false);
  const [isApiBeingCalled, setApiCallState] = useState(false);
  const [cpfValue, setCpf] = useState("");
  const [propertyValue, setProperty] = useState("");
  const [nameValue, setName] = useState("");
  const [birthdateValue, setBirthdate] = useState("");
  const [carValue, setCar] = useState("");
  const [urlValue, setUrl] = useState("");
  const [file, setFile] = useState(null);
  const [emailValue, setEmail] = useState("");
  const [progressValue, setProgress] = useState(0);
  const [cpfValid, setValidCpf] = useState(false);
  const [birthdateValid, setValidBirthdate] = useState(false);
  const fileInput = useRef(null);
  const [fileError, setFileError] = useState(false);

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    uploadToCloud(file);
    event.target.value = "";
  };
  const uploadToCloud = (file) => {
    const date = new Date();
    const storageRef = ref(firebaseStorage, `${process.env.REACT_APP_FILES_FOLDER}/${date.toUTCString()}-${file.name}`);
    setProgress(1);
    var uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        var progress =
          Math.round(snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(progress);
      },
      (error) => {
        throw error;
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          setFile(file);
          setUrl(url);
          setProgress(0);
        });
      }
    );
  };

  const saveData = async () => {
    
    try {
      setApiCallState(true);
      const docRef = collection(firestoreDocuments, `${process.env.REACT_APP_FILES_DATABASE}`);      
      const payload = {
        id: uuidv4(),
        name: nameValue,
        propertyName: propertyValue,
        cpf: cpfValue,
        birthdate: birthdateValue,
        car_number: carValue,
        url: urlValue,
        email: emailValue,
        datetime: new Date().toISOString(),
      };

      console.log(payload);
      
      const auth = getAuth();

      await signInAnonymously(auth)
        .then(async () => {

          console.log("alright")

          await addDoc(docRef, payload);
          setApiCallState(false);
          navigate("/feedback");
          console.log("yeah baby")
        })
        .catch((error) =>
        {
            console.log(`Auth error: ${error}`)
            throw error;
        });      
      
    } catch (error) {      
        console.log(`General error: ${error}`)
        setApiCallState(false);
      return;
    }
  };
  const cleanCpf = () => cpfValue.replace(/[^0-9]/g, "");
  const cleanBirthdate = () => birthdateValue.replace(/[^0-9]/g, "");
  const cleanCarNumber = () => carValue.replace(/[^a-zA-Z0-9]/g, "");

  return (
    <div className="w-screen bg-blueDark min-h-screen">
      {isModalVisible && (
        <UserAlertModal
          dismiss={() => {
            setModalVisible(false);
          }}
        />
      )}
      <div className="grid grid-cols-12 gap-4 max-w-7xl mx-auto">
        <div className="col-span-12 flex justify-between py-6">
          <div className="flex">
            <i
              onClick={returnForm}
              className="icon icon-arrow-down  transform rotate-90 bg-white cursor-pointer"
            ></i>
            <p className="ml-5 font-bold text-white text-lg">
            Cadastro de Propriedade Rural
            </p>
          </div>
          <i
            onClick={() => {
              setModalVisible(true);
            }}
            className="icon icon-close bg-white cursor-pointer"
          ></i>
        </div>
      </div>
      <div className="w-full h-0.5 border-b border-gray relative">
        <div
          className="absolute h-full bg-neon bottom-0 left-0 "
          style={{ width: `${((formStepState.index + 1) / 7) * 100}%` }}
        ></div>
      </div>
      <div className="grid grid-cols-12 gap-4 max-w-7xl mx-auto">

        <div
          className={`py-14 md:col-span-8  col-span-10 md:col-start-2 col-start-2  ${
            formStepState.index === 0 ? "" : "hidden"
          }`}
        >
          <h2 className="text-white font-bold text-5xl mb-9">
            Confirme os dados antes de enviar a solicitação
          </h2>          
          <RegistrationDataDisplay
            label="Qual é o número do CAR da propriedade?"
            input={carValue}
            onSave={(value) => {
              setCar(value);
            }}
          />
          <RegistrationDataDisplay
            label="Qual o nome completo do proprietário(a) da terra?"
            input={nameValue}
            onSave={(value) => {
              setName(value);
            }}
          />
          <RegistrationDataDisplay
            label="Qual é o nome da propriedade?"
            input={propertyValue}
            onSave={(value) => {
              setProperty(value);
            }}
          />
          <RegistrationDataDisplay
            label="Qual o CPF do proprietário(a) da terra?"
            input={cpfValue}
            onSave={(value) => {
              setCpf(value);
            }}
          />
          <RegistrationDataDisplay
            label="Qual a data de nascimento do proprietário(a) da terra?"
            input={birthdateValue}
            onSave={(value) => {
              setBirthdate(value);
            }}
          />
          <RegistrationDataDisplay
            label="Qual o melhor email para entrarmos em contato?"
            input={emailValue}
            onSave={(value) => {
              setEmail(value);
            }}
          />
          <button
            disabled={isApiBeingCalled}
            onClick={saveData}
            style={{ "min-width": "292px" }}
            className="bg-solarBase py-3 text-white font-bold font-lg pl-6 pr-5 rounded-sm mt-5 hover:bg-solarLight focus:border focus:border-2 md:w-auto w-full "
          >
            {!isApiBeingCalled && <p>Enviar solicitação</p>}
            {isApiBeingCalled && <div className="loader "></div>}
          </button>
        </div>
      </div>
    </div>
  );
}

export default RegisterFormComplete;

const faqContent=[
    {
        question:'O que é a Moss?',
        answer: ['A MOSS é a maior plataforma ambiental do mundo criada para unir pessoas e empresas com o objetivo de combater as mudanças climáticas globais unindo soluções ambientais com tecnologias inovadoras, simplificando e democratizando a sustentabilidade para que todos façam parte desse movimento.'],
    },
    {
        question:'O que são NFTs?',
        answer: ['O que são NFTs? NFT é uma sigla para non-fungible token – ou token não-fungível. É um código único com registro digital de origem e gravado em blockchain, o que viabiliza a aquisição e validação segura de propriedade.'],
    },
    {
        question:'Como o Moss Forest vai tornar minhas terras produtivas?',
        answer: ['Transformamos a sua propriedade em um ativo digital, que se converte em créditos. Quanto mais rica a fauna e flora do terreno, mais créditos ele vale. Isso funciona de forma que essa flora, compensa a emissão de carbono de outros lugares.','Esses créditos são como uma moeda digital, que chamamos de MCO2. Por meio da tecnologia do blockchain, transformamos a MCO2 em um ativo de investimento, que se valoriza e sempre reverte para a preservação das terras.'],
    }
];
export default faqContent;


import TagManager from 'react-gtm-module'
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const DataLayer = () => {
    const location = useLocation();
    const { pathname } = location;

    TagManager.initialize({
      gtmId: 'GTM-T7NK8MN'
    });
  
    useEffect(() => {
        const paths = ['how-it-works', 'registration', 'feedback', 'tutorial'];
        let pageType = 'home';
        paths.forEach(path => {
            if (pathname.includes(path)) {
                pageType = path === 'how-it-works' ? 'FAQ' : path;
            }
        });

      TagManager.dataLayer({
        dataLayer :{
          appName: 'Moss Forest',
          visitorLoginState: 'OFF',
          pageType
        }
      });
  
      TagManager.dataLayer({
        dataLayer: {
          event: 'virtualPageView',
          pageTitle: document.title,
          pageURL: pathname,
        }
      });
    }, [pathname]);

    return null
}

export default DataLayer
import { useState } from "react";
function RegistrationDataDisplay({ input, label, onSave }) {
  const [isEditing, setIsEditing] = useState(false);
  return (
    <div>
      {!isEditing && (
        <div className="w-full py-1.5 pl-2 pr-3 bg-darkerGray mb-1 flex justify-between">
          <div>
            <p className="text-xs text-white font-medium mb-0.5">{label}</p>
            <div className="text-xl text-white font-normal">{input}</div>
          </div>
          <button onClick={() => setIsEditing(true)} className="p-1">
            <i className="icon icon-edit bg-white"></i>
          </button>
        </div>
      )}
      {isEditing && (
        <div className="w-full relative py-1.5 pl-2 pr-3 mb-1 border-b border-solarBase">
            <p className="text-xs text-white font-medium mb-0.5">{label}</p>
          <input
            className="w-full bg-transparent outline-none text-3xl text-lightGray "
            type="text"
            name="name"
            id="username_field"
            placeholder=""
            onChange={(event) => {
              const { value } = event.target;
              onSave(value);
            }}
            value={input}
          />
          <button
            onClick={() => setIsEditing(false)}
            className="p-1 absolute right-3"
          >
            <i className="icon icon-check bg-white"></i>
          </button>
        </div>
      )}
    </div>
  );
}

export default RegistrationDataDisplay;

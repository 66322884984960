const tutorialSteps = [
    {
        title:'Se você perdeu o documento CAR da sua propriedade ',
        descritpion: ['Alguns estados (como o Pará) permitem que você consiga o número do CAR apenas inserindo o número do seu CPF no portal do Sicar regional.','Se isso não for possível você deve consultar a Secretaria de Estado do Meio Ambiente da sua região ou o técnico responsável que fez o CAR da sua propriedade.']
    },
    {
        title:'Se sua propriedade ainda não possui o CAR',
        descritpion: ['Para fazer o CAR consulte a Secretaria de Estado do Meio Ambiente da sua região ou um técnico ambiental.']
    }
];
export default tutorialSteps;


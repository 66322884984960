const howWorksContent = [
  {
    title: "Envio dos dados do proprietário(a)",
    descritpion: [
      "Precisamos de alguns dados pessoais (RG, CPF ou CNH) de quem é proprietário(a) da terra, e documentos da propriedade (CAR, Certidão de Inteiro Teor ou Escritura Pública). Com essas informações em mãos, conseguimos iniciar o processo.",
    ],
  },
  {
    title: "Número do CAR da propriedade",
    descritpion: [
      "CAR é um registro público eletrônico, obrigatório para todas as propriedades rurais brasileiras que reúne informações ambientais das propriedades e posses rurais. Pode ser baixado no Sistema Nacional de Cadastro Ambiental Rural do Serviço Florestal Brasileiro.",
      "O Número do seu CAR é uma sequência de letras e números localizada na parte superior do documento iniciada pela sigla do seu estado, exemplo: ",
      "PA-1508357-EF53.E5F9.9DA8.424F.BE78.4B59.C047.66E7",
    ],
  },
  {
    title: "Shapefiles da propriedade",
    descritpion: [
      "O shape da propriedade é um arquivo que determina as coordenadas, tamanho e formato do terreno, vamos usá-lo para dimensionar o quanto pode compensar a produção de carbono e como pode ser convertido para créditos digitais. ",
    ],
  },
  {
    title: "Análise Moss",
    descritpion: [
      "Com todos esses dados nós fazemos uma análise 100% digital do que pode ser feito na propriedade e como é possível tornar ela mais produtiva mantendo os recursos naturais preservados.",
    ],
  },
];
export default howWorksContent;

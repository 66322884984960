
import faqContent from "../data/faq-content";
import FaqCard from "./faq-card";
import faqEmail from '../assets/faq-email.png'
function FaqList() {
    
  return (
    <div>
      {faqContent.map((e, index) => (
        <FaqCard key={index} faq={e} cardIndex={index}/>
      ))}
      <div className="w-full bg-darkerGray h-10 rounded-sm px-2 flex mt-12">
          <img className="h-10 w-auto" src={faqEmail} alt="faq-email"/>
          <div className="ml-2 my-auto text-lg text-white font-normal">Ainda tem dúvidas? Mande um e-mail para: <a href="mailto:falecom@moss.earth" className="font-bold">falecom@moss.earth</a></div>
      </div>
    </div>
  );
}

export default FaqList;

import { Link } from "react-router-dom";
function TipCard({ type }) {
  
  return (
    <div
      className="w-full p-3 border border-gray rounded-sm flex flex-col my-auto "
      style={{ height: "fit-content" }}
    >
      <div className="flex mb-2">
        <div className="bg-darkerGray h-4.5 w-4.5 rounded-sm mr-1 flex ">
          <i className="icon-lamp bg-neon m-auto icon"></i>
        </div>
        <p className="text-white font-bold text-base my-auto">Dica</p>
      </div>
      {type === "car" && (
        <p className="text-white font-normal text-base">
          CAR é um registro público eletrônico, obrigatório para todas as
          propriedades rurais brasileiras. Pode ser baixado no Sistema Nacional
          de Cadastro Ambiental Rural do Serviço Florestal Brasileiro.
        </p>
      )}
      {type === "shapefile" && (
        <p className="text-white font-normal text-base">
          Os shapefile são arquivos que determinam as coordenadas, tamanho e
          formato do terreno, vamos usá-lo para dimensionar o quanto pode
          compensar a produção de carbono e como pode ser convertido para
          créditos digitais.
        </p>
      )}
      {type === "car" && (
       <Link to="tutorial" target="_blank" rel="noopener noreferrer">
        <div
          className="text-white underline font-semibold text-base mt-2 cursor-pointer"
          
        >
          Artigo: Como conseguir o CAR da minha propriedade
        </div>
       </Link>
      )}
      {type === "shapefile" && (
        <Link to="/tutorial-shapefiles" target="_blank" rel="noopener noreferrer">
          <div className="text-white underline font-semibold text-base mt-2 cursor-pointer">
            Artigo: Como conseguir o shapefile da minha propriedade
          </div>
        </Link>
      )}
    </div>
  );
}

export default TipCard;

import logo from "../assets/logo-plataforma-dark.svg";
import homeImg from "../assets/img-how-large.svg";
import homeImgSmall from "../assets/img-home-small.png";
import HowItWorksList from "../components/how-it-works-list";
import { Link } from "react-router-dom";
import FaqList from "../components/faq-list";
function HowItWorks() {
  return (
    <div className="w-screen bg-white relative">
      <div className=" min-h-screen ">
        <div className="w-screen p-4.5 border-b border-darkerGray">
          <a href="/">
            <img
              className=" mx-auto cursor-pointer"
              src={logo}
              alt="Moss Logo"
              height="48"
              width="134.55"
            />
          </a>
        </div>
        <div className="grid grid-cols-12 gap-4 max-w-7xl mx-auto w-full">
          <div className="md:col-start-3 md:col-span-6 col-start-2 col-span-10  md:mb-auto md:mt-16 md:pt-2 mt-7">
            <h1 className="text-6xl text-darkerGray font-bold">
              Como funciona o Moss Forest?
            </h1>
          </div>
        </div>
        <div className="botom-img w-screen ">
          <img
            src={homeImg}
            alt="imagem-bottom-homepage"
            className="w-screen md:block hidden"
          />
          <img
            src={homeImgSmall}
            alt="imagem-bottom-homepage"
            className="w-screen block md:hidden"
          />
          <div className="h-full  w-full bg-lightestGray flex">
            <div className="grid grid-cols-12 gap-4 max-w-7xl mx-auto w-full">
              <div className="md:col-start-3 md:col-span-6 col-start-2 col-span-10 pt-11">
                <p>
                  O Moss Forest é um sistema que analisa propriedades rurais e,
                  através de informações ambientais, indica
                  <span className="font-semibold">
                    {" "}
                    soluções economicamente viáveis{" "}
                  </span>
                  para a conservação dos recursos naturais
                </p>
                <p className="mt-4">Veja o passo a passo de como funciona:</p>
              </div>
              <div className="md:col-start-3 md:col-span-8 col-start-2 col-span-10 pt-4">
                <HowItWorksList />
                <h3 className="font-bold text-darkBlue text-4xl mt-6">
                  Cadastre sua propriedade no Moss Forest e saiba como torná-la
                  mais produtiva.
                </h3>
                <div className="md:mt-8 mt-5 cursor-pointer">
                  <Link key="1" to="/registration">
                    <button className="bg-solarBase py-3 flex text-white font-bold font-lg pl-6 pr-5 rounded-sm mb-14 hover:bg-solarLight md:w-auto w-full md:justify-start justify-between">
                      <p>Quero certificar</p>
                      <i className="icon icon-arrow-down  transform -rotate-90 ml-3 my-auto bg-white"></i>
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="min-h-screen w-full bg-white">
        <div className="faq-container grid grid-cols-12 gap-4 max-w-7xl mx-auto">
          <div className="faq-title md:col-start-3 md:col-span-8 col-start-2 col-span-10 mt-10">
            <h2 className="text-6xl font-bold">Perguntas Frequentes</h2>
            <FaqList />
          </div>
        </div>
      </div>
      <div className="w-full fixed bottom-0 bg-lightestGray">
        <div className="cursor-pointer mx-auto w-full max-w-7xl">
          <Link key="2" to="/registration">
            <button className="bg-solarBase py-3 flex justify-between text-white font-bold font-lg pl-6 pr-5 rounded-sm mb-14 w-full hover:bg-solarLight md:w-auto w-full md:justify-start justify-between">
              <p>Começar certificação</p>
              <i className="icon icon-arrow-down  transform -rotate-90 ml-3 my-auto bg-white"></i>
            </button>
          </Link>
        </div>
      </div>

    </div>
  );
}

export default HowItWorks;

import tutorialSteps from "../data/tutorial-steps";

function TutorialSteps() {
    return (   <div>
        {tutorialSteps.map((e, index) => (
          <div className="mb-3" key={index}>
            <div className="flex">
              
              <p className="mb-2 text-4xl font-bold text-darkestGray my-auto">
                {e.title}
              </p>
            </div>
            {e.descritpion.map((el,index)=>(<p key={index}>{el}</p>))}
            
          </div>
        ))}
      </div>);
}

export default TutorialSteps;
import logo from "../assets/logo-plataforma-dark.svg";
import { useNavigate, useLocation } from "react-router-dom";
import faqEmail from '../assets/faq-email.png'
import ilustra from "../assets/img-ilustra-shape.png";
import stepOne from "../assets/img-tuto-shape-1.png";
import stepTwo from "../assets/img-tuto-shape-2.png";
import stepThree from "../assets/img-tuto-shape-3.png";
import stepFour from "../assets/img-tuto-shape-4.png";
import Footer from "../components/footer";
function TutorialShapeFiles() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { showBackButton } = state ?? false;
  return (
    <div className="w-screen bg-white">
      <div className=" min-h-screen ">
        <div className="w-screen p-4.5 border-b border-darkerGray relative">
          <div className="absolute flex h-full ">
            {showBackButton && (
              <div className="flex">
                <i
                  onClick={() => navigate("/how-it-works")}
                  className="icon icon-arrow-down  transform rotate-90  cursor-pointer"
                ></i>
                <p className="md:ml-5 ml-1 font-bold text-black text-lg">
                  Voltar
                </p>
              </div>
            )}
          </div>
          <a className="md:block hidden" href="/">
            <img
              className=" mx-auto cursor-pointer"
              src={logo}
              alt="Moss Logo"
              height="48"
              width="134.55"
            />
          </a>
        </div>
        <div className="grid grid-cols-12 gap-4 max-w-7xl mx-auto w-full">
          <div className="md:col-start-3 md:col-span-6 col-start-2 col-span-10  md:mb-auto md:mt-16 md:pt-2 mt-7">
            <div
              className="bg-greenLight p-1 rounded-sm text-white font-bold mb-2"
              style={{ width: "fit-content" }}
            >
              Tutorial
            </div>
            <h1 className="md:text-6xl text-darkerGray font-bold text-4xl mb-10 md:mb-0">
              Como baixar os shapefiles da minha propriedade?
            </h1>
          </div>
        </div>
        <div className="w-screen bg-lightestGray md:mt-9 mt-16 ">
          <div className="grid grid-cols-12 gap-4 max-w-7xl mx-auto w-full relative">
            <div className="md:absolute md:right-0 md:transform md:-translate-y-2/3 col-start-2 col-span-10 -mt-20 md:mt-0">
              <img className="mx-auto" src={ilustra} alt="" srcset="" style={{'max-width':'264px'}} />
            </div>
            <div className="md:col-start-3 md:col-span-6 col-start-2 col-span-10 pt-11">
              <p className="mb-3">
                Todos as propriedades que possuem CAR podem ter seus shapefiles
                acessados através do portal do Sicar nacional, veja o passo a
                passo:
              </p>
              <div className="w-full">
                <div className="flex">
                  <div className="bg-blueDark h-4 w-4 rounded-sm flex mr-2">
                    <span className="text-neon font-bold m-auto">1</span>
                  </div>
                  <div className="font-bold text-blueDark text-lg my-auto">
                    Acesse o portal do Sicar nacional
                  </div>
                </div>
                <div className="text-lg-font-normal-text-darkestGrey mt-2">
                  Você deve acessar o site do Sistema Nacional de Cadastro
                  Ambiental Rural e abrir o módulo de{" "}
                  <a
                    className="text-solarBase underline"
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.car.gov.br/publico/imoveis/index"
                  >
                    Consulta Pública.
                  </a>
                </div>
                <img src={stepOne} className="mt-2 mb-8" alt="" />
              </div>
              <div className="w-full">
                <div className="flex">
                  <div className="bg-blueDark h-4 w-4 rounded-sm flex mr-2">
                    <span className="text-neon font-bold m-auto">2</span>
                  </div>
                  <div className="font-bold text-blueDark text-lg my-auto">
                    Pesquise com o seu número CAR
                  </div>
                </div>
                <div className="text-lg-font-normal-text-darkestGrey mt-2">
                  Você deve clicar no ícone de pesquisa (lupa) no canto superior
                  direito, digitar o número de matrícula do CAR da sua
                  propriedade e clicar novamente no ícone de busca.
                </div>
                <img src={stepTwo} className="mt-2 mb-8" alt="" />
              </div>
              <div className="w-full">
                <div className="flex">
                  <div className="bg-blueDark h-4 w-4 rounded-sm flex mr-2">
                    <span className="text-neon font-bold m-auto">3</span>
                  </div>
                  <div className="font-bold text-blueDark text-lg my-auto">
                    Confira se é a sua propriedade
                  </div>
                </div>
                <div className="text-lg-font-normal-text-darkestGrey mt-2">
                  Agora vão aparecer os detalhes da propriedade, se estiver tudo
                  certo, clique no botão “Realizar download shapefile”.
                </div>
                <img src={stepThree} className="mt-2 mb-8" alt="" />
              </div>
              <div className="w-full">
                <div className="flex">
                  <div className="bg-blueDark h-4 w-4 rounded-sm flex mr-2">
                    <span className="text-neon font-bold m-auto">4</span>
                  </div>
                  <div className="font-bold text-blueDark text-lg my-auto">
                    Realize o download dos shapefiles
                  </div>
                </div>
                <div className="text-lg-font-normal-text-darkestGrey mt-2">
                  Quando aparecer a tela de confirmação você deve digitar os
                  caracteres que aparecerem na imagem e clicar no botão
                  “Download”.
                </div>
                <img src={stepFour} className="mt-2 mb-8" alt="" />
              </div>
              <div className="w-full">
                <div className="flex">
                  <div className="bg-blueDark h-4 w-4 rounded-sm flex mr-2">
                    <span className="text-neon font-bold m-auto">5</span>
                  </div>
                  <div className="font-bold text-blueDark text-lg my-auto">
                    Faça o upload do arquivo no Moss Forest
                  </div>
                </div>
                <div className="text-lg-font-normal-text-darkestGrey mt-2">
                  Você deve ter baixado um arquivo .zip, localize esse arquivo
                  no seu computador e faça o upload dele quando estiver fazendo
                  o cadastro da sua propriedade no Moss Forest.
                </div>
               
              </div>

              <div className="w-full bg-darkerGray h-10 rounded-sm px-2 flex my-12">
                <img className="h-10 w-auto" src={faqEmail} alt="faq-email" />
                <div className="ml-2 my-auto text-lg text-white font-normal">
                  Ainda tem dúvidas? Mande um e-mail para:{" "}
                  <a href="mailto:falecom@moss.earth" className="font-bold">
                    falecom@moss.earth
                  </a>
                </div>
              </div>
              
            </div>
          </div>
        </div>
      </div>
      <div className="bg-lightestGray">
      <Footer/>
      </div>
    </div>
  );
}

export default TutorialShapeFiles;

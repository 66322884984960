import logo from "../assets/logo-plataforma.png";
import homeImg from "../assets/img-home-large.png";
import homeImgSmall from "../assets/img-home-small.png";
import Footer from "../components/footer";
import { Link } from "react-router-dom";
function Homepage() {
  return (
    <div className="bg-black min-h-screen flex items-start flex-col relative">
      <div className="w-screen p-4.5 border-b border-darkerGray">
        <a href="/">
          <img
            className="mx-auto cursor-pointer"
            src={logo}
            alt="Moss Logo"
            height="48"
            width="134.55"
          />
        </a>
      </div>
      <div className="grid grid-cols-12 gap-3 h-full">
        <div className="col-start-2 col-span-10 md:col-span-5 md:col-start-2 md:mb-auto md:mt-16 md:pt-2 mt-7">
          <h1 className="text-6xl font-bold text-white md:block hidden">
            Projeto <br />
            Moss Forest
          </h1>
          <p className="text-white mt-5 text-3xl">
            Uma solução sustentável para tornar sua{" "}
            <span className="font-semibold">
              propriedade rural mais produtiva.
            </span>
          </p>
          <div className="md:mt-8 mt-5 cursor-pointer">
            <Link to="/how-it-works">
              <button className="bg-solarBase py-3 flex text-white font-bold font-lg pl-6 pr-5 rounded-sm mb-14 hover:bg-solarLight md:w-auto w-full md:justify-start justify-between">
                <p>Quero certificar</p>
                <i className="icon icon-arrow-down  transform -rotate-90 ml-3 my-auto bg-white"></i>
              </button>
            </Link>
          </div>
        </div>
      </div>
      <div className="botom-img w-screen mt-auto ">
        <img
          src={homeImg}
          alt="imagem-bottom-homepage"
          className="w-screen md:block hidden"
        />
        <img
          src={homeImgSmall}
          alt="imagem-bottom-homepage"
          className="w-screen block md:hidden"
        />
        <div className="h-5 md:h-20 w-full bg-darkerGray flex">
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default Homepage;

import logo from "../assets/logo-plataforma-dark.svg";
import { useNavigate, useLocation } from "react-router-dom";
import TutorialSteps from "../components/tutorial-steps";
import { Link } from "react-router-dom";
import ilustra from "../assets/ilustra-car.svg";
import tutorial from "../assets/img-tutocar.png";
function Tutorial() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { showBackButton } = state ?? false;
  return (
    <div className="w-screen bg-white">
      <div className=" min-h-screen ">
        <div className="w-screen p-4.5 border-b border-darkerGray relative">
          <div className="absolute flex h-full ">
            {showBackButton && (
              <div className="flex">
                <i
                  onClick={() => navigate("/how-it-works")}
                  className="icon icon-arrow-down  transform rotate-90  cursor-pointer"
                ></i>
                <p className="md:ml-5 ml-1 font-bold text-black text-lg">
                  Voltar
                </p>
              </div>
            )}
          </div>
          <a className="md:block hidden" href="/">
            <img
              className=" mx-auto cursor-pointer"
              src={logo}
              alt="Moss Logo"
              height="48"
              width="134.55"
            />
          </a>
        </div>
        <div className="grid grid-cols-12 gap-4 max-w-7xl mx-auto w-full">
          <div className="md:col-start-3 md:col-span-6 col-start-2 col-span-10  md:mb-auto md:mt-16 md:pt-2 mt-7">
            <div className="bg-greenLight p-1 rounded-sm text-white font-bold mb-2" style={{width:"fit-content"}}>
              Tutorial
            </div>
            <h1 className="text-6xl text-darkerGray font-bold">
              Não consigo encontrar o número CAR da minha propriedade. O que
              fazer?
            </h1>
          </div>
        </div>
        <div className="w-screen bg-lightestGray md:mt-9 mt-14">
          <div className="grid grid-cols-12 gap-4 max-w-7xl mx-auto w-full relative">
            <div className="md:absolute md:right-0 md:transform md:-translate-y-1/2 col-start-2 col-span-10 -mt-10 md:mt-0">
              <img className="mx-auto" src={ilustra} alt="" srcset="" />
            </div>
            <div className="md:col-start-3 md:col-span-6 col-start-2 col-span-10 pt-11">
              <p className="mb-3">
                O número de matrícula do CAR pode ser encontrado no documento de
                Cadastro Ambiental Rural, você deve encontrá-lo no topo do
                documento.
              </p>
              <p className="mb-3">
                Esse número de matrícula é padronizado, sempre se iniciando com
                a sigla do estado onde a propriedade se localiza, e se segue com
                uma série de caracteres, que envolvem letras e números.
              </p>
              <p className="mb-3">Veja o exemplo a seguir:</p>
              <p className="mb-8 font-semibold">
                UF-1302405-E6D3.395B.6D27.4F42.AE22.DD56.987C.DD52
              </p>
              <div className="w-full">
                <img src={tutorial} alt="" srcset="" />
              </div>
            </div>
            <div className="md:col-start-3 md:col-span-8 col-start-2 col-span-10 pt-4">
              <TutorialSteps />
              <h3 className="font-bold text-darkBlue text-4xl mt-6">
                O CAR é um documento obrigatório para todas as propriedades
                rurais brasileiras e precisamos do número da matrícula para
                cadastrar a sua propriedade no Moss Forest.
              </h3>
              <div className="md:mt-8 mt-5 cursor-pointer">
                <Link key="1" to="/registration">
                  <button className="bg-solarBase py-3 flex text-white font-bold font-lg pl-6 pr-5 rounded-sm mb-14 hover:bg-solarLight md:w-auto w-full md:justify-start justify-between">
                    <p>Quero certificar</p>
                    <i className="icon icon-arrow-down  transform -rotate-90 ml-3 my-auto bg-white"></i>
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Tutorial;

const validators = {
  birthdate: (date) => {

    const regexVar = new RegExp("([0-9]{2})/([0-9]{2})/([0-9]{4})");
    const regexVarTest = regexVar.test(date);
    const parts = date.split("/");
    const day = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10);
    const year = parseInt(parts[2], 10);
    const nowDate = new Date();
    if((nowDate.getFullYear() -year) > 150 || (nowDate.getFullYear()-year) <18 || month === 0 || month > 12)
        return false;

    const monthLength = [ 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31 ];
    if(year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0))
        monthLength[1] = 29;
    
    const dayTest = day > 0 && day <= monthLength[month - 1];
    
    return regexVarTest && dayTest;
  },
};
export default validators;

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {getStorage } from 'firebase/storage';
import {getFirestore } from 'firebase/firestore';
import 'firebase/firestore';
import 'firebase/auth';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDU5CK0jY_Y1A08UqXel2N7Zsohd3uhdRQ",
  authDomain: "moss-forest.firebaseapp.com",
  projectId: "moss-forest",
  storageBucket: "moss-forest.appspot.com",
  messagingSenderId: "319328912296",
  appId: "1:319328912296:web:6234168975e985a407d39d"
};

export const firebaseApp =initializeApp(firebaseConfig);
export const firebaseStorage = getStorage(firebaseApp);
export const firestoreDocuments = getFirestore(firebaseApp);
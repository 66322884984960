import { useState, useRef } from "react";
import MaskedInput from "../components/masked-input";
import { cpf, cnpj } from "cpf-cnpj-validator";
import RegistrationDataDisplay from "../components/registration-data-display";
import validators from "../utils/date-validator";
import { firebaseStorage } from "../base";
import { firestoreDocuments } from "../base";
import { useNavigate } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import { ref, uploadBytesResumable, getDownloadURL } from "@firebase/storage";
import { addDoc, collection } from "@firebase/firestore";
import { getAuth, signInAnonymously } from "firebase/auth";
import UserAlertModal from "../components/modal";
import TipCard from "../components/tip-card";
import * as EmailValidator from "email-validator";
import { v4 as uuidv4 } from 'uuid';
import TagManager from "react-gtm-module";
import { useEffect } from "react";

function RegisterForm() {

  const { getRootProps, getInputProps } = useDropzone({
    accept: ".pdf",
    multiple: true,
    onDrop: (acceptedFiles, fileRejections) => {
      if (fileRejections.length > 0) {
        setFileError(true);
        setDragState(false);
        return;
      }
      setFileError(false);
      setDragState(false);
      uploadToCloud(acceptedFiles[0]);
    },
    onDragEnter: (event) => {
      setDragState(true);
      console.log(event);
    },
    onDragOver: (event) => {
      event.preventDefault();
    },
  });

  const navigate = useNavigate();
  const [formStepState, setformStepState] = useState({
    index: 0,
  });

  useEffect(() => {
    TagManager.dataLayer({
      gtmId: process.env.REACT_APP_GTM_ID,
      dataLayer: {
        event: 'forestSignUp',
        signUpStep: formStepState.index + 1,
      },
    });

    if (formStepState.index !== 0) {
      TagManager.dataLayer({
        dataLayer :{
          appName: 'Moss Forest',
          visitorLoginState: 'OFF',
          pageType: 'registration'
        }
      });
  
      TagManager.dataLayer({
        dataLayer: {
          event: 'virtualPageView',
          pageTitle: document.title,
          pageURL: window.location.href,
        }
      });
    }

  }, [formStepState.index]);

  function advanceForm() {
    setformStepState({
      ...formStepState,
      index: formStepState.index + 1,
    });
  }
  function returnForm() {
    if (formStepState.index > 0) {
      setformStepState({
        ...formStepState,
        index: formStepState.index - 1,
      });
    } else {
      navigate("/how-it-works");
    }
  }
  const [isModalVisible, setModalVisible] = useState(false);
  const [isDragging, setDragState] = useState(false);
  const [isApiBeingCalled, setApiCallState] = useState(false);
  const [cpfValue, setCpf] = useState("");
  const [cnpjValue, setCnpj] = useState("");
  const [propertyValue, setProperty] = useState("");
  const [nameValue, setName] = useState("");
  const [birthdateValue, setBirthdate] = useState("");
  const [carValue, setCar] = useState("");
  const [urlValue, setUrl] = useState("");
  const [file, setFile] = useState(null);
  const [emailValue, setEmail] = useState("");
  const [progressValue, setProgress] = useState(0);
  const [cpfValid, setValidCpf] = useState(false);
  const [cnpjValid, setValidCnpj] = useState(false);
  const [birthdateValid, setValidBirthdate] = useState(false);
  const fileInput = useRef(null);
  const [fileError, setFileError] = useState(false);

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    uploadToCloud(file);
    event.target.value = "";
  };
  const uploadToCloud = (file) => {
    const date = new Date();
    const storageRef = ref(firebaseStorage, `${process.env.REACT_APP_FILES_FOLDER}/${date.toUTCString()}-${file.name}`);
    setProgress(1);
    var uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        var progress =
          Math.round(snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(progress);
      },
      (error) => {
        throw error;
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          setFile(file);
          setUrl(url);
          setProgress(0);
        });
      }
    );
  };

  const saveData = async () => {
    
    try {
      setApiCallState(true);
      const docRef = collection(firestoreDocuments, `${process.env.REACT_APP_FILES_DATABASE}`);      
      const payload = {
        id: uuidv4(),
        name: nameValue,
        propertyName: propertyValue,
        cpf: cpfValue,
        cnpj: cnpjValue,
        birthdate: birthdateValue,
        car_number: carValue,
        url: urlValue,
        email: emailValue,
        datetime: new Date().toISOString(),
      };

      console.log(payload);
      
      const auth = getAuth();

      await signInAnonymously(auth)
        .then(async () => {

          console.log("alright")

          await addDoc(docRef, payload);
          setApiCallState(false);
          navigate("/feedback");
          console.log("yeah baby")
        })
        .catch((error) =>
        {
            console.log(`Auth error: ${error}`)
            throw error;
        });      
      
    } catch (error) {      
        console.log(`General error: ${error}`)
        setApiCallState(false);
      return;
    }
  };
  const cleanCpf = () => cpfValue.replace(/[^0-9]/g, "");
  const cleanCnpj = () => cnpjValue.replace(/[^0-9]/g, "");
  const cleanBirthdate = () => birthdateValue.replace(/[^0-9]/g, "");
  const cleanCarNumber = () => carValue.replace(/[^a-zA-Z0-9]/g, "");

  return (
    <div className="w-screen bg-blueDark min-h-screen">
      {isModalVisible && (
        <UserAlertModal
          dismiss={() => {
            setModalVisible(false);
          }}
        />
      )}
      <div className="grid grid-cols-12 gap-4 max-w-7xl mx-auto">
        <div className="col-span-12 flex justify-between py-6">
          <div className="flex">
            <i
              onClick={returnForm}
              className="icon icon-arrow-down  transform rotate-90 bg-white cursor-pointer"
            ></i>
            <p className="ml-5 font-bold text-white text-lg">
            Cadastro de Propriedade Rural
            </p>
          </div>
          <i
            onClick={() => {
              setModalVisible(true);
            }}
            className="icon icon-close bg-white cursor-pointer"
          ></i>
        </div>
      </div>
      <div className="w-full h-0.5 border-b border-gray relative">
        <div
          className="absolute h-full bg-neon bottom-0 left-0 "
          style={{ width: `${((formStepState.index + 1) / 7) * 100}%` }}
        ></div>
      </div>
      <div className="grid grid-cols-12 gap-4 max-w-7xl mx-auto">
        
        <div
          className={`py-14 md:col-span-8  col-span-10 md:col-start-2 col-start-2 ${
            formStepState.index === 0 ? "" : "hidden"
          }`}
        >
          <h2 className="text-white font-bold text-5xl mb-9">
            Qual o nome completo do proprietário(a) da terra?
          </h2>
          <div className="input-container mt-0.5 w-full border-b-2 border-gray py-3 px-2">
            <input
              className="w-full bg-transparent outline-none text-3xl text-lightGray"
              type="text"
              name="name"
              id="username_field"
              placeholder="Nome e Sobrenome"
              onChange={(event) => {
                const { value } = event.target;
                setName(value);
              }}
              value={nameValue}
            />
          </div>
          <button
            disabled={!nameValue.length > 0}
            onClick={advanceForm}
            className="bg-solarBase py-3 flex text-black font-bold font-lg pl-6 pr-5 rounded-sm mt-16 hover:bg-solarLight focus:border focus:border-2 md:w-auto w-full md:justify-start justify-between "
          >
            <p>Próximo passo</p>
            <i className="icon icon-arrow-down  transform -rotate-90 ml-3 my-auto"></i>
          </button>
        </div>
        
        <div
          className={`py-14 md:col-span-8  col-span-10 md:col-start-2 col-start-2 ${
            formStepState.index === 1 ? "" : "hidden"
          }`}
        >
          <h2 className="text-white font-bold text-5xl mb-9">
            Qual é o nome da propriedade?
          </h2>
          <div className="input-container mt-0.5 w-full border-b-2 border-gray py-3 px-2">
            <input
              className="w-full bg-transparent outline-none text-3xl text-lightGray"
              type="text"
              name="name"
              id="property_field"
              placeholder="Nome da Propriedade"
              onChange={(event) => {
                const { value } = event.target;
                setProperty(value);
              }}
              value={propertyValue}
            />
          </div>
          <button
            disabled={!propertyValue.length > 0}
            onClick={advanceForm}
            className="bg-solarBase py-3 flex text-black font-bold font-lg pl-6 pr-5 rounded-sm mt-16 hover:bg-solarLight focus:border focus:border-2 md:w-auto w-full md:justify-start justify-between "
          >
            <p>Próximo passo</p>
            <i className="icon icon-arrow-down  transform -rotate-90 ml-3 my-auto"></i>
          </button>
        </div>        
        
        <div
          className={`py-14 md:col-span-8  col-span-10 md:col-start-2 col-start-2 ${
            formStepState.index === 2 ? "" : "hidden"
          }`}
        >
          <h2 className="text-white font-bold text-5xl mb-9">
            Qual o CPF e/ou CNPJ do proprietário(a) da terra?
          </h2>
          <div className="input-container mt-0.5 w-1/3 border-b-2 border-gray py-3 px-2 relative float-left">
            {!cpfValid && cleanCpf().length === 11 && (
              <div className="absolute -bottom-4 flex">
                <i className="icon icon-close bg-white w-2 h-2 my-auto mr-1"></i>
                <p className="text-white font-normal text-sm my-auto">
                  CPF inválido
                </p>
              </div>
            )}

            <h2 className="text-white font-bold text-2xl mb-2">CPF:</h2>
            <MaskedInput
              mask="999.999.999-99"
              placeholder="000.000.000-00"
              value={cpfValue}
              onChange={(event) => {
                const { value } = event.target;
                setValidCpf(cpf.isValid(value));
                setCpf(value);
              }}
            />
          </div>

          <div className="input-container mt-0.5 ml-3 w-1/3 border-b-2 border-gray py-3 px-2 relative float-left">
            {!cnpjValid && cleanCnpj().length === 14 && (
              <div className="absolute -bottom-4 flex">
                <i className="icon icon-close bg-white w-2 h-2 my-auto mr-1"></i>
                <p className="text-white font-normal text-sm my-auto">
                  CNPJ inválido
                </p>
              </div>
            )}
            <h2 className="text-white font-bold text-2xl mb-2">CNPJ:</h2>
            <MaskedInput
              mask="99.999.999/9999-99"
              placeholder="00.000.000/0000-00"
              value={cnpjValue}
              onChange={(event) => {
                const { value } = event.target;
                setValidCnpj(cnpj.isValid(value));
                setCnpj(value);
              }}
            />
          </div>
          <br></br>
          <br></br>
          <br></br>
          <button
            disabled={!cpfValid && !cnpjValid}
            onClick={advanceForm}
            className="bg-solarBase py-3 flex text-black font-bold font-lg pl-6 pr-5 rounded-sm mt-16 hover:bg-solarLight focus:border focus:border-2 md:w-auto w-full md:justify-start justify-between"
          >
            <p>Próximo passo</p>
            <i className="icon icon-arrow-down  transform -rotate-90 ml-3 my-auto "></i>
          </button>
        </div>
        
        <div
          className={`py-14 md:col-span-8  col-span-10 md:col-start-2 col-start-2 ${
            formStepState.index === 3 ? "" : "hidden"
          }`}
        >
          <h2 className="text-white font-bold text-5xl mb-9">
            Qual a data de nascimento do proprietário(a) da terra?
          </h2>
          <div className="input-container mt-0.5 w-full border-b-2 border-gray py-3 px-2 relative">
            {!birthdateValid && cleanBirthdate().length === 8 && (
              <div className="absolute -bottom-4 flex">
                <i className="icon icon-close bg-white w-2 h-2 my-auto mr-1"></i>
                <p className="text-white font-normal text-sm my-auto">
                  Data inválida
                </p>
              </div>
            )}
            <MaskedInput
              mask="99/99/9999"
              value={birthdateValue}
              placeholder="dd / mm / aaaa"
              onChange={(event) => {
                const { value } = event.target;
                const isValid = validators.birthdate(value);
                setValidBirthdate(isValid);
                setBirthdate(value);
              }}
            />
          </div>
          <button
            // disabled={!birthdateValid}
            onClick={advanceForm}
            className="bg-solarBase py-3 flex text-black font-bold font-lg pl-6 pr-5 rounded-sm mt-16 hover:bg-solarLight focus:border focus:border-2 md:w-auto w-full md:justify-start justify-between"
          >
            <p>Próximo passo</p>
            <i className="icon icon-arrow-down  transform -rotate-90 ml-3 my-auto"></i>
          </button>
        </div>

        <div
          className={`grid grid-cols-12 gap-4 col-span-12  ${
            formStepState.index === 4 ? "" : "hidden"
          }`}
        >
          <div className="lg:col-span-8 py-14 col-span-10 lg:col-start-2 col-start-2">
            <h2 className="text-white font-bold text-5xl mb-9">
            Caso tenha, faça o upload do arquivo PDF do SICAR da propriedade
            </h2>
            <div className="input-container mt-0.5 w-full py-3 px-2">
              <input
                type="file"
                name="car-upload"
                id="car-upload"
                className="hidden"
                onChange={(e) => handleFileChange(e)}
                ref={fileInput}
                accept=".zip"
              />
              {urlValue.length === 0 && (
                <div
                  {...getRootProps()}
                  className={`w-full h-20 text-center border-2 border-lightGray rounded-md flex flex-col border-dashed relative cursor-pointer ${
                    isDragging ? "bg-greenBase" : ""
                  }`}
                >
                  <input {...getInputProps()} />
                  <div className="my-auto">
                    <div className="h-4.5 w-4.5 bg-darkestGray rounded mx-auto flex mb-1">
                      {progressValue === 0 && (
                        <i className="icon-plus icon bg-neon m-auto"></i>
                      )}
                      {progressValue !== 0 && (
                        <div className="loader m-auto"></div>
                      )}
                    </div>
                    <p className="text-sm font-bold text-white">
                      Clique aqui para selecionar o arquivo
                    </p>
                  </div>
                  {fileError && (
                    <div className="absolute md:-bottom-4 -bottom-5 flex">
                      <i className="icon icon-close bg-white w-2 h-2 my-auto mr-1"></i>
                      <p className="text-white font-normal text-sm my-auto">
                        Formato de arquivo inválido. O arquivo deve possuir a
                        extensão .pdf
                      </p>
                    </div>
                  )}
                </div>
              )}
              {urlValue.length > 0 && file && (
                <div className="w-full h-20 text-center border-2 border-lightGray rounded-md flex flex-col bg-darkerGray px-3">
                  <div className="my-auto flex justify-between w-full">
                    <div className="flex">
                      <div className="h-4.5 w-4.5 bg-darkestGray rounded mx-auto flex mr-1">
                        <i className="icon-file icon bg-neon m-auto"></i>
                      </div>
                      <p className="text-sm font-bold text-white my-auto">
                        {file.name}
                      </p>
                    </div>
                    <div
                      className="h-4.5 w-4.5 flex my-auto"
                      onClick={() => {
                        setFile(null);
                        setUrl("");
                      }}
                    >
                      <i className="icon-trash icon bg-white m-auto cursor-pointer"></i>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <button
              // disabled={!urlValue.length > 0}
              onClick={advanceForm}
              className="bg-solarBase py-3 flex text-black font-bold font-lg pl-6 pr-5 rounded-sm mt-16 hover:bg-solarLight focus:border focus:border-2 md:w-auto w-full md:justify-start justify-between"
            >
              <p>Próximo passo</p>
              <i className="icon icon-arrow-down  transform -rotate-90 ml-3 my-auto"></i>
            </button>
          </div>
          {/* <div className="lg:col-span-3 col-span-10 lg:col-start-10 flex col-start-2 mb-3 lg:mb-0">
            <TipCard type='shapefile'/>
          </div> */}
        </div>

        <div
          className={` grid grid-cols-12 gap-4 col-span-12  ${
            formStepState.index === 5 ? "" : "hidden"
          }`}
        >
          <div className=" lg:col-span-8 py-14 col-span-10 lg:col-start-2 col-start-2">
            <h2 className="text-white font-bold text-5xl mb-9">
              Qual é o número do CAR da propriedade?
            </h2>
            <div className="input-container mt-0.5 w-full border-b-2 border-gray py-3 px-2">
              <MaskedInput
                mask="aa-*******-****.****.****.****.****.****.****.****"
                value={carValue}
                placeholder="UF-1302405-E6D3.395B.6D27.4F42.AE22.DD56.987C.DD52"
                onChange={(event) => {
                  const { value } = event.target;
                  setCar(value.toUpperCase());
                }}
              />
            </div>
            <button
              disabled={!(cleanCarNumber().length === 41)}
              onClick={advanceForm}
              className="bg-solarBase py-3 flex text-black font-bold font-lg pl-6 pr-5 rounded-sm mt-16 hover:bg-solarLight focus:border focus:border-2 md:w-auto w-full md:justify-start justify-between "
            >
              <p>Próximo passo</p>
              <i className="icon icon-arrow-down  transform -rotate-90 ml-3 my-auto"></i>
            </button>
          </div>
          <div className="lg:col-span-3 col-span-10 lg:col-start-10 flex md:col-start-2 col-start-2 mb-3 lg:mb-0">
            <TipCard type='car' />
          </div>
        </div>
        
        <div
          className={`py-14 md:col-span-8  col-span-10  md:col-start-2 col-start-2  ${
            formStepState.index === 6 ? "" : "hidden"
          }`}
        >
            <h2 className="text-white font-bold text-5xl mb-9">
              Qual o melhor email para entrarmos em contato?
            </h2>
            <div className="input-container mt-0.5 w-full border-b-2 border-gray py-3 px-2">
              <input
                className="w-full bg-transparent outline-none text-3xl text-lightGray"
                type="text"
                name="birthdate"
                id="birthdate_field"
                placeholder="Insira o e-mail"
                onChange={(event) => {
                  const { value } = event.target;
                  setEmail(value);
                }}
              />
            </div>
            <button
              disabled={!EmailValidator.validate(emailValue)}
              onClick={advanceForm}
              className="bg-solarBase py-3 flex text-black font-bold font-lg pl-6 pr-5 rounded-sm mt-16 hover:bg-solarLight focus:border focus:border-2 md:w-auto w-full md:justify-start justify-between"
            >
              <p>Próximo passo</p>
              <i className="icon icon-arrow-down  transform -rotate-90 ml-3 my-auto"></i>
            </button>
          
        </div>

        <div
          className={`py-14 md:col-span-8  col-span-10 md:col-start-2 col-start-2  ${
            formStepState.index === 7 ? "" : "hidden"
          }`}
        >
          <h2 className="text-white font-bold text-5xl mb-9">
            Confirme os dados antes de enviar a solicitação
          </h2>
          <RegistrationDataDisplay
            label="Nome do proprietário(a)"
            input={nameValue}
            onSave={(value) => {
              setName(value);
            }}
          />
          <RegistrationDataDisplay
            label="Nome da propriedade"
            input={propertyValue}
            onSave={(value) => {
              setProperty(value);
            }}
          />
          <RegistrationDataDisplay
            label="Data de nascimento"
            input={birthdateValue}
            onSave={(value) => {
              setBirthdate(value);
            }}
          />
          <RegistrationDataDisplay
            label="CPF"
            input={cpfValue}
            onSave={(value) => {
              setCpf(value);
            }}
          />
          <RegistrationDataDisplay
            label="CNPJ"
            input={cnpjValue}
            onSave={(value) => {
              setCnpj(value);
            }}
          />
          <RegistrationDataDisplay
            label="E-mail para contato"
            input={emailValue}
            onSave={(value) => {
              setEmail(value);
            }}
          />
          <RegistrationDataDisplay
            label="Número do CAR"
            input={carValue}
            onSave={(value) => {
              setCar(value);
            }}
          />
          <button
            disabled={isApiBeingCalled}
            onClick={saveData}
            style={{ "min-width": "292px" }}
            className="bg-solarBase py-3 text-white font-bold font-lg pl-6 pr-5 rounded-sm mt-5 hover:bg-solarLight focus:border focus:border-2 md:w-auto w-full "
          >
            {!isApiBeingCalled && <p>Enviar solicitação</p>}
            {isApiBeingCalled && <div className="loader "></div>}
          </button>
        </div>
      </div>
    </div>
  );
}

export default RegisterForm;

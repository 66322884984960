function UserAlertModal({ dismiss }) {
  return (
    <div
      className="w-screen h-screen fixed bg-black bg-opacity-40 z-10"
      onClick={dismiss}
    >
      <div
        className="bg-white p-5 absolute md:w-1/3 w-5/6"
        style={{
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          "border-radius": "2px 8px 2px 2px",
          "max-width": "384px",
        }}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <p className="text-lg font-semibold text-black mb-6 ">
          Não conseguiremos salvar os dados que você já preencheu se você não
          enviar o formulário. Deseja mesmo sair?
        </p>
        <button
          onClick={dismiss}
          className="bg-solarBase py-2 w-full text-center text-base font-bold rounded-sm"
        >
          Voltar para o formulário
        </button>
        <a href="/">
        <button
          onClick={dismiss}
          className="border-2 border-solarBase py-2 w-full text-center text-base font-bold mt-1 rounded-sm"
        >
          Sair
        </button>
        </a>
      </div>
    </div>
  );
}

export default UserAlertModal;

import "./App.css";
import Homepage from "./pages/Homepage.jsx";
import HowItWorks from "./pages/HowItWorks";
import "./styles/output.css";
import { Routes, Route } from "react-router-dom";
import RegisterForm from "./pages/RegisterForm";
import Feedback from "./pages/Feedback";
import Tutorial from "./pages/Tutorial";
import Placeholder from "./pages/placeholder"
import TutorialShapeFiles from './pages/TutorialShapeFiles'
import DataLayer from "./components/dataLayer";
import RegisterFormComplete from "./pages/RegisterFormComplete";

function App() {
      
  return (
    <>
      <DataLayer />
      <Routes>
        <Route path="/" exact element={<Homepage />} />
        <Route path="/placeholder" exact element={<Placeholder/>} />
        <Route path="/how-it-works" element={<HowItWorks />} />
        <Route path="/registration" element={<RegisterForm />} />
        <Route path="/new-registration" element={<RegisterFormComplete />} />
        <Route path="/feedback" element={<Feedback />} />
        <Route path="/tutorial" element={<Tutorial />} />
        <Route path="/tutorial-shapefiles" element={<TutorialShapeFiles />} />
        <Route path="*" element={<Homepage />} />
      </Routes>
    </>
  );
}

export default App;

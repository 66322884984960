import logo from "../assets/logo-plataforma-dark.svg";
import feedbackImg from "../assets/img-ilustra-feedback-large.png";
// import homeImgSmall from "../assets/img-home-small.png";
// import HowItWorksList from "../components/how-it-works-list";
// import { Link } from "react-router-dom";
function Feedback() {
  return (
    <div className="w-screen bg-white">
      <div className=" min-h-screen ">
        <div className="w-screen p-4.5 border-b border-darkerGray">
         <a href="/">
         <img
            className=" mx-auto cursor-pointer"
            src={logo}
            alt="Moss Logo"
            height="48"
            width="134.55"
          />
         </a>
        </div>
        <div className="md:grid grid-cols-none md:grid-cols-12 gap-4 max-w-7xl mx-auto " style={{ height: "calc(100vh - 121px)" }}>
          <div className="col-span-5 col-start-2 flex p-4 md:p-0">
            <img
              className="my-auto"
              src={feedbackImg}
              alt="feedback"
              srcset=""
              style={{ height: "436px" }}
            />
          </div>
          <div className="col-span-4  my-auto pl-5">
            <h1 className="text-6xl text-blueDark font-normal">
              Solicitação enviada!
            </h1>
            <p className="text-lg text-darkerGray font-normal mb-6 mt-2">
              Agora nós vamos analisar a sua solicitação e em 48 horas te
              enviamos a resposta via e-mail.
            </p>
            <a href="http://moss.earth">
            <button className="border-2 border-solarBase px-5 py-2.5 cursor-pointer font-bold text-lg">
              Visitar o site da Moss
            </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Feedback;

import InputMask from "react-input-mask";
const MaskedInput = ({ mask, placeholder, value, onChange }) => {
  
  return (
    <InputMask
      mask={mask}
      className="w-full bg-transparent outline-none text-3xl text-lightGray"
      type="text"
      name="cpf"
      id="cpf_field"
      maskPlaceholder=""
      placeholder={placeholder}
      value={value}
      onChange={onChange}
    />
  );
};
export default MaskedInput;

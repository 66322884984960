import howWorksContent from "../data/how-it-works-content";
import { useNavigate } from "react-router-dom";
function HowItWorksList() {
  const navigate = useNavigate();
  return (
    <div>
      {howWorksContent.map((e, index) => (
        <div className="mb-3" key={index}>
          <div className="flex">
            <div className="h-5 w-5 rounded-sm bg-blueDark text-neon text-lg font-bold flex mr-1.5">
              <span className="m-auto">{index + 1}</span>
            </div>
            <p className="mb-2 text-4xl font-bold text-darkestGray my-auto">
              {e.title}
            </p>
          </div>
          {e.descritpion.map((el, index) => (
            <p className={`${index===(e.descritpion.length-1)?"":"mb-3"}`} key={index}>{el}</p>
          ))}
          {index === 1 && (
            <p
              onClick={() =>
                navigate("/tutorial", { state: { showBackButton: true } })
              }
              className="font-bold underline text-lg text-solarDark mt-3 cursor-pointer"
            >
              Tutorial: Não consigo encontrar o número CAR da minha propriedade.
              O que fazer?
            </p>
          )}
          {index === 2 && (
            <p
              onClick={() =>
                navigate("/tutorial-shapefiles", { state: { showBackButton: true } })
              }
              className="font-bold underline text-lg text-solarDark mt-3 cursor-pointer"
            >
              Tutorial: Como conseguir os shapes da minha propriedade?
            </p>
          )}
        </div>
      ))}
    </div>
  );
}

export default HowItWorksList;

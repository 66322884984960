import { useState } from "react";
function FaqCard(props) {
  const [cardState, setCardState] = useState({
    isOpen: false,
  });
  function toggleCard() {
    setCardState({
      ...cardState,
      isOpen: !cardState.isOpen,
    });
  }
  return (
    <div
      key={props.cardIndex}
      onClick={toggleCard}
      className="faq-container w-full border-b border-lighterGray py-3"
    >
      <div className="header flex justify-between mb-2 cursor-pointer">
        <p className="text-darkestGray font-semibold text lg">
          {props.faq.question}
        </p>
        <i
          className={`icon icon-arrow-down ${
            cardState.isOpen ? "icon-active" : "icon-inactive"
          }`}
        ></i>
      </div>
      <div className={`faq-card-body ${cardState.isOpen ? "" : "hidden"}`}>
        {props.faq.answer.map((e, index) => (
          <p key={index} className="mb-2">
            {e}
          </p>
        ))}
      </div>
    </div>
  );
}

export default FaqCard;
